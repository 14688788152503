import _ from 'lodash'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'
import { VenueInfoService } from '@sevenrooms/core/api'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
import type { Dispatch } from 'redux'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initializeAction = (globalInit: any) =>
  ({
    type: GlobalActionTypes.INITIALIZE,
    globalInit,
  } as const)

export const updateVenueInfo = (venue: unknown) =>
  ({
    type: GlobalActionTypes.UPDATE_VENUE_INFO,
    venue,
  } as const)

export const showInfoMessage = (message: string) =>
  ({
    type: GlobalActionTypes.SHOW_INFO_MESSAGE,
    message,
  } as const)

export const showSuccessMessage = (message: string) =>
  ({
    type: GlobalActionTypes.SHOW_SUCCESS_MESSAGE,
    message,
  } as const)

export const showWarnMessage = (message: string) =>
  ({
    type: GlobalActionTypes.SHOW_WARN_MESSAGE,
    message,
  } as const)

export const showErrorMessage = (message: string) =>
  ({
    type: GlobalActionTypes.SHOW_ERROR_MESSAGE,
    message,
  } as const)

export const hideMessage = () => ({ type: GlobalActionTypes.HIDE_MESSAGE } as const)

export const userDomainVenuesLoaded = () =>
  ({
    type: GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD,
  } as const)

export const userDomainVenueGroupsLoaded = () =>
  ({
    type: GlobalActionTypes.ON_USER_DOMAIN_VENUE_GROUPS_LOAD,
  } as const)

export const onChangeVenue = (venue: UserDomainVenue) =>
  ({
    type: GlobalActionTypes.CHANGE_VENUE,
    venue,
  } as const)

export const onChangeVenueIntent = (venue: Venue) =>
  ({
    type: GlobalActionTypes.CHANGE_VENUE_INTENT,
    venue,
  } as const)

export const processFail = (message: string, type: string) =>
  batchActions([
    showErrorMessage(message),
    {
      type,
    },
  ])

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ensureHaveBookingInfo = (maybeVenueId?: string) => (dispatch: Dispatch<ReturnType<typeof updateVenueInfo>>, getState: any) => {
  const state = getState()
  const venueId = maybeVenueId ?? state.appState.venue.id
  const venue = _.find(state.appState.userDomain.venues, { id: venueId })
  if (venue.isFullInfo) {
    return venue
  }
  return VenueInfoService.getFullVenueInfo(venueId).then(venue => {
    dispatch(updateVenueInfo(venue))
    return venue
  })
}

export const ensureHaveBookingInfoMany =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (venueIds: string[]) => (dispatch: Dispatch<ReturnType<typeof updateVenueInfo>>, getState: any) => {
    const state = getState()
    const venues = venueIds.map(venueId => _.find(state.appState.userDomain.venues, { id: venueId }))
    const missingVenues = Object.fromEntries(venues.filter(venue => !venue.isFullInfo).map(venue => [venue.id, true]))
    if (_.isEmpty(missingVenues)) {
      return venues
    }
    return Promise.all(venues.map(venue => (venue.isFullInfo ? Promise.resolve(venue) : VenueInfoService.getFullVenueInfo(venue.id)))).then(
      venues => {
        dispatch(batchActions(venues.filter(venue => missingVenues[venue.id]).map(venue => updateVenueInfo(venue))))
        return venues
      }
    )
  }
