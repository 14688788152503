export const CHANGE_DATE = 'CHANGE_DATE'
export const CHANGE_SHIFT = 'CHANGE_SHIFT'

export const GO_TO_NOW = 'GO_TO_NOW'
export const GET_TODAYS_SHIFTS = 'GET_TODAYS_SHIFTS'

export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_FAIL = 'GET_SHIFTS_FAIL'

export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS'
export const GET_BLOCKS_FAIL = 'GET_BLOCKS_FAIL'

export const GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS'
export const GET_ACCESS_FAIL = 'GET_ACCESS_FAIL'

export const GET_ACTUALS_SUCCESS = 'GET_ACTUALS_SUCCESS'
export const GET_ACTUALS_FAIL = 'GET_ACTUALS_FAIL'
export const GET_ACTUALS_NOT_READY = 'GET_ACTUALS_NOT_READY'

export const GET_WAITLIST_SUCCESS = 'GET_WAITLIST_SUCCESS'
export const GET_WAITLIST_FAIL = 'GET_WAITLIST_FAIL'
export const GET_WAITLIST_NOT_READY = 'GET_WAITLIST_NOT_READY'

export const GET_SEATINGAREA_TABLES_SUCCESS = 'GET_SEATINGAREA_TABLES_SUCCESS'
export const GET_SEATINGAREA_TABLES_FAIL = 'GET_SEATINGAREA_TABLES_FAIL'

export const GET_AUTOASSIGN_PROBLEMS_SUCCESS = 'GET_AUTOASSIGN_PROBLEMS_SUCCESS'
export const GET_AUTOASSIGN_PROBLEMS_FAIL = 'GET_AUTOASSIGN_PROBLEMS_FAIL'

export const GRID_DATA_READY = 'GRID_DATA_READY'
export const SUSPEND_GRID = 'SUSPEND_GRID'

export const CHANGE_GRID_TABLE_SORT = 'CHANGE_GRID_TABLE_SORT'

export const ADD_DROPDOWN_OPENED = 'ADD_DROPDOWN_OPENED'
export const ADD_DROPDOWN_CLOSED = 'ADD_DROPDOWN_CLOSED'

export const STATUS_DROPDOWN_OPENED = 'STATUS_DROPDOWN_OPENED'
export const STATUS_DROPDOWN_CLOSED = 'STATUS_DROPDOWN_CLOSED'

export const ADD_NEW_RESERVATION = 'ADD_NEW_RESERVATION'
export const ADD_NEW_REQUEST = 'ADD_NEW_REQUEST'
export const ADD_NEW_BLOCK = 'ADD_NEW_BLOCK'

export const SHOW_ACTUAL = 'SHOW_ACTUAL'
export const SHOW_BLOCK = 'SHOW_BLOCK'
export const SHOW_ACCESS_RULE = 'SHOW_ACCESS_RULE'

export const BLOCK_ADDED = 'BLOCK_ADDED'
export const BLOCK_REMOVED = 'BLOCK_REMOVED'

export const SAVE_RES_STATUS_SUCCESS = 'SAVE_RES_STATUS_SUCCESS'
export const SAVE_RES_STATUS_FAIL = 'SAVE_RES_STATUS_FAIL'

export const SAVE_RES_TABLE_ASSIGNMENT_SUCCESS = 'SAVE_RES_TABLE_ASSIGNMENT_SUCCESS'
export const SAVE_RES_TABLE_ASSIGNMENT_FAIL = 'SAVE_RES_TABLE_ASSIGNMENT_FAIL'

export const SAVE_RES_DURATION_SUCCESS = 'SAVE_RES_DURATION_SUCCESS'
export const SAVE_RES_DURATION_FAIL = 'SAVE_RES_DURATION_FAIL'

export const SHOW_CONFLICT_DIALOG = 'SHOW_CONFLICT_DIALOG'
export const CLOSE_CONFLICT_DIALOG = 'CLOSE_CONFLICT_DIALOG'

export const START_ACTUAL_MOVE_MODE = 'START_ACTUAL_MOVE_MODE'
export const CANCEL_ACTUAL_MOVE_MODE = 'CANCEL_ACTUAL_MOVE_MODE'

export const AVAILABILITY_GET_TIMES_START = 'AVAILABILITY_GET_TIMES_START'
export const AVAILABILITY_GET_TIMES_FAIL = 'AVAILABILITY_GET_TIMES_FAIL'
export const AVAILABILITY_GET_TIMES_NOT_READY = 'AVAILABILITY_GET_TIMES_NOT_READY'

export const SET_COVERFLOW_ACTIVE = 'SET_COVERFLOW_ACTIVE'

export const SET_DAILY_NOTE_ACTIVE = 'SET_DAILY_NOTE_ACTIVE'
export const GET_DAILY_EVENTS_START = 'GET_DAILY_EVENTS_START'
export const GET_DAILY_EVENTS_SUCCESS = 'GET_DAILY_EVENTS_SUCCESS'
export const SAVE_DAILY_NOTE_START = 'SAVE_DAILY_NOTE_START'
export const SAVE_DAILY_NOTE_SUCCESS = 'SAVE_DAILY_NOTE_SUCCESS'
export const SAVE_DAILY_NOTE_FAIL = 'SAVE_DAILY_NOTE_FAIL'
export const UPDATE_DAILY_NOTE = 'UPDATE_DAILY_NOTE'

export const FLOORPLAN_CLICK_TABLE_POSITION = 'FLOORPLAN_CLICK_TABLE_POSITION'
export const FLOORPLAN_CHANGE_FLOORPLAN_ROOM = 'FLOORPLAN_CHANGE_FLOORPLAN_ROOM'
export const FLOORPLAN_ENTER_TABLE_ASSIGN_MODE = 'FLOORPLAN_ENTER_TABLE_ASSIGN_MODE'
export const FLOORPLAN_CLOSE_TABLE_CARD = 'FLOORPLAN_CLOSE_TABLE_CARD'
export const POST_SELECTED_FLOORPLAN_LAYOUT_SUCCESS = 'POST_SELECTED_FLOORPLAN_LAYOUT_SUCCESS'
export const POST_SELECTED_FLOORPLAN_LAYOUT_FAIL = 'POST_SELECTED_FLOORPLAN_LAYOUT_FAIL'

export const SET_SELECTED_ACCESS_RULE = 'SET_SELECTED_ACCESS_RULE'
