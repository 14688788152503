export const INITIALIZE = 'INITIALIZE'
export const ON_USER_DOMAIN_VENUES_LOAD = 'ON_USER_DOMAIN_VENUES_LOAD'
export const ON_USER_DOMAIN_VENUE_GROUPS_LOAD = 'ON_USER_DOMAIN_VENUE_GROUPS_LOAD'
export const UPDATE_VENUE_INFO = 'UPDATE_VENUE_INFO'

export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'

export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE'
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE'
export const SHOW_WARN_MESSAGE = 'SHOW_WARN_MESSAGE'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'

export const CHANGE_VENUE = 'CHANGE_VENUE'
export const CHANGE_VENUE_INTENT = 'CHANGE_VENUE_INTENT'
export const CHANGE_NAVIGATION_DATE = 'CHANGE_NAVIGATION_DATE'
export const CHANGE_HIGHLIGHTED_DATES = 'CHANGE_HIGHLIGHTED_DATES'
export const GET_VENUE_STATIC_DATA_SUCCESS = 'GET_VENUE_STATIC_DATA_SUCCESS'
export const GET_VENUE_STATIC_DATA_FAIL = 'GET_VENUE_STATIC_DATA_FAIL'
