import moment from 'moment'
import { createGlobalStyle } from 'styled-components'
import * as locales from 'date-fns/locale'
import React from 'reactOrig'
import * as dp from 'reactDatepickerOrig'

const localesByCode = {};
Object.keys(locales).forEach(key => localesByCode[locales[key].code] = locales[key])

const DatePicker = dp.default

const ReactDatePickerPolyfill = (props, ref) => {
    const withoutMoment = clearMoment({ ...props })
    // withoutMoment.minDate = withoutMoment.minDate ? withoutMoment.minDate.toDate() : withoutMoment.minDate;
    // withoutMoment.selected = withoutMoment.selected ? withoutMoment.selected.toDate() : withoutMoment.selected;

    const onChange = withoutMoment.onChange

    withoutMoment.onChange = d => {
        const wrap = moment(d)
        onChange(wrap)
    }

    const localeCode = props.locale ? props.locale.replace('_', '-') : "en-GB";
    const locale = localesByCode[localeCode]

    return (
      <React.Fragment>
        <DatePickerFixes />
        <DatePicker {...withoutMoment} ref={ref} locale={locale} />
      </React.Fragment>
    )
}

ReactDatePickerPolyfill.displayName = 'ReactDatePickerPolyfill';


const DatePickerFixes = createGlobalStyle`
  .react-datepicker__navigation {
    font-size: 0;
    z-index: 1;
    height: 0;
    background: transparent;
  }

  .react-datepicker__tab-loop .react-datepicker__navigation--previous {
    right: 46px;
    left: initial;
  }

  .react-datepicker-popper {
      z-index: 100;
  }
`

export default React.forwardRef(ReactDatePickerPolyfill)

function clearMoment(props) {
    Object.keys(props).forEach(key => {
        const prop = props[key]

        if (Array.isArray(prop)) {
            prop.forEach((el, i) => { 'toDate' in el ? prop[i] = el.toDate() : null })
        }

        if (typeof prop === 'object' && 'toDate' in prop) {
            props[key] = props[key].toDate()
        }
    })

    return props
}
