import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import * as NavActionTypes from 'mgr/lib/actions/NavActionTypes'
import {
  getInitialAppState,
  reduceGlobalInitAttrs,
  reduceUserDomainVenueGroups,
  reduceUserDomainVenues,
  updateUserDomainVenue,
} from 'mgr/lib/utils/AppStateUtils'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

export const appStateReducer = (state = getInitialAppState(), action) => {
  switch (action.type) {
    case GlobalActionTypes.INITIALIZE:
      return reduceGlobalInitAttrs(state, action.globalInit)
    case GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD:
      return reduceUserDomainVenues(state)
    case GlobalActionTypes.UPDATE_VENUE_INFO:
      return {
        ...state,
        userDomain: updateUserDomainVenue(state.userDomain, action.venue),
      }
    case GlobalActionTypes.ON_USER_DOMAIN_VENUE_GROUPS_LOAD:
      return reduceUserDomainVenueGroups(state)
    case GlobalActionTypes.CHANGE_VENUE: {
      return {
        ...state,
        venue: action.venue,
        isLoading: true,
      }
    }
    case GlobalActionTypes.CHANGE_VENUE_INTENT: {
      return {
        ...state,
        changeVenueIntent: true,
        changeVenueIntentObj: action.venue,
      }
    }
    case GlobalActionTypes.CHANGE_NAVIGATION_DATE: {
      return { ...state, selectedNavDate: action.date }
    }
    case GlobalActionTypes.CHANGE_HIGHLIGHTED_DATES: {
      return { ...state, highlightedDates: action.dates }
    }
    case GlobalActionTypes.GET_VENUE_STATIC_DATA_SUCCESS: {
      return {
        ...state,
        conciergeAccess: action.staticData.concierges,
      }
    }
    case ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS: {
      return {
        ...state,
        floorPlan: action.seatingAreaTables,
      }
    }
    default:
      return state
  }
}

export const lastActionReducer = (state = {}, action) => action

export const loadingReducer = (state = { isLoading: false, useDisableScreen: false }, action) => {
  switch (action.type) {
    case GlobalActionTypes.START_LOADING:
      return {
        ...state,
        isLoading: true,
        useDisableScreen: action.useDisableScreen,
      }
    case GlobalActionTypes.END_LOADING:
      return { ...state, isLoading: false, useDisableScreen: false }
    default:
      return state
  }
}

export const navReducer = (
  state = {
    activeSection: null,
    subNavSection: null,
  },
  action
) => {
  switch (action.type) {
    case NavActionTypes.UPDATE_NAV_STATE:
      return {
        ...state,
        activeSection: action.activeSection,
        subNavSection: action.subNavSection,
      }
    default:
      return state
  }
}

const initialStatusMessage = {
  message: '',
  level: 'INFO', // INFO, WARN, ERROR, SUCCESS
  visible: false,
}

export const statusMessageReducer = (state = initialStatusMessage, action) => {
  switch (action.type) {
    case GlobalActionTypes.SHOW_INFO_MESSAGE:
      return {
        ...state,
        message: action.message,
        level: 'INFO',
        visible: true,
      }
    case GlobalActionTypes.SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        message: action.message,
        level: 'SUCCESS',
        visible: true,
      }
    case GlobalActionTypes.SHOW_WARN_MESSAGE:
      return {
        ...state,
        message: action.message,
        level: 'WARN',
        visible: true,
      }
    case GlobalActionTypes.SHOW_ERROR_MESSAGE:
      return {
        ...state,
        message: action.message,
        level: 'ERROR',
        visible: true,
      }
    case GlobalActionTypes.HIDE_MESSAGE:
      return {
        ...state,
        message: state.message,
        level: state.level,
        visible: false,
      }
    default:
      return state
  }
}
