import React from 'react'
import styled from 'styled-components'
import { getContrastingColor } from 'svr/common/Color'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'

const Icon = styled(StyledVmsIconXS)`
  padding-right: ${props => props.theme.gutter.half};
`

const GeneralTag = styled.span`
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-size: 14px;
  font-weight: 500;
  float: left;
  margin: 2px;
  border-radius: 11px;
  box-sizing: border-box;
  padding: ${props => props.theme.gutter.half} ${props => props.theme.gutter.standard};
  background-color: ${props => props.tagColor};
  color: ${props => props.fontColor};
  overflow: hidden;
`

const TagName = styled.div`
  flex: 1;
  ${props => (props.hasIcon ? `padding-left: ${props.theme.gutter.half};` : null)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TagElement = ({ tagObj }) => {
  const hasTagGroup = tagObj.hasOwnProperty('tagGroup')
  const tagColor = hasTagGroup ? tagObj.tagGroup.color_hex : tagObj.tag_color || tagObj.tagColor
  const fontColor = tagObj.text_color || tagObj.textColor || getContrastingColor(tagColor)
  const name = hasTagGroup ? tagObj.tagGroup.tag_name_display : tagObj.tag_name_display || tagObj.tag_name
  const isAutotag = hasTagGroup ? tagObj.tagGroup.is_autotag : tagObj.is_autotag
  const icon = tagObj.icon ? tagObj.icon : isAutotag ? <Icon>{VmsIcons.Refresh}</Icon> : null

  return (
    <GeneralTag tagColor={tagColor} fontColor={fontColor}>
      {icon}
      <TagName hasIcon={!!icon}>{name}</TagName>
    </GeneralTag>
  )
}

const TagGenerator = tagsDisplay => tagsDisplay.map(elem => <TagElement key={elem.tagHash} tagObj={elem} />)

const FlexTagContainer = styled.ul`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

const TagArea = ({ tags }) => <FlexTagContainer>{TagGenerator(tags)}</FlexTagContainer>

export default TagArea
