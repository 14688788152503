export const ManagerPermissions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (venue: any) => ({
    canAddBlocks: ['SUPERUSER', 'MANAGER'].includes(venue.privilegeLevel) || venue.features.can_edit_booking_block,
    canOverbook: venue.features.can_overbook,
    canCharge: venue.features.can_charge,
    canRefund: venue.features.can_refund,
    canOverbookPacing: venue.features.can_overbook_pacing,
    canOverbookLargerTables: venue.features.can_overbook_larger_tables,
    canOverbookSmallerTables: venue.features.can_overbook_smaller_tables,
    canOverbookEnforcedShiftPartySize: venue.features.can_overbook_enforced_shift_party_size,
    canOverbookNoTables: venue.features.can_overbook_no_tables,
    canOverbookTotalShiftCovers: venue.features.can_overbook_total_shift_covers,
    canOverbookAccessBlocks: venue.features.can_overbook_access_blocks,
    canOverbookRequiredPayment: venue.features.can_override_payment_requirement,
    canOverrideTableCombos: ['SUPERUSER', 'MANAGER'].includes(venue.privilegeLevel),
    canSelectBookedBy: ['SUPERUSER', 'MANAGER', 'SUBMANAGER'].includes(venue.privilegeLevel),
    canAdministrateShiftsAccessRules: venue.features.can_administrate_shifts_access_rules,
  }),
}
