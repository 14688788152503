import { batchActions as batchActionsDefault } from 'redux-batched-actions'

const handleInnerAction = (dispatch, getState, action) => {
  if (typeof action === 'function') {
    return new Promise(resolve => {
      let isResolved = false
      const innerDispatch = innerAction => {
        if (isResolved) {
          return dispatch(innerAction)
        }
        return resolve(handleInnerAction(dispatch, getState, innerAction))
      }
      action(innerDispatch, getState)
      isResolved = true
    })
  }
  return Promise.resolve(action)
}

/**
 *
 * @param {any} actions
 * @returns {any}
 */
export const batchActions = actions => (dispatch, getState) => {
  const promises = actions.map(action => handleInnerAction(dispatch, getState, action))
  return Promise.all(promises).then(objectActions => dispatch(batchActionsDefault(objectActions)))
}
