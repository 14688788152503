import { ManagerPermissions } from './ManagerPermissions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reduceUserDomainVenueAttrs(userDomainVenue: any) {
  if (!userDomainVenue) {
    return {}
  }
  const venueShort = {
    id: userDomainVenue.id,
    name: userDomainVenue.name,
    internalDisplayName: userDomainVenue.internal_name || userDomainVenue.name,
    venueClass: userDomainVenue.venue_class,
    isDiningClass: userDomainVenue.venue_class === 'DINING',
    isNightlifeClass: userDomainVenue.venue_class === 'NIGHTLIFE',
    urlKey: userDomainVenue.url_key,
    venueGroupId: userDomainVenue.venue_group_id,
  }
  if (!userDomainVenue.is_full_info) {
    return venueShort
  }
  const venue = {
    ...venueShort,
    isFullInfo: true,
    locale: userDomainVenue.locale,
    currencySymbol: userDomainVenue.currency_symbol,
    countryCode: userDomainVenue.country_code,
    currencyCode: userDomainVenue.currency_code,
    timezone: userDomainVenue.timezone,
    startOfDayHour: userDomainVenue.start_of_day_hour,
    privilegeLevel: userDomainVenue.privilege,
    features: userDomainVenue.features,
    isAutoAssignEnabled: userDomainVenue.autoselect_table,
    isSevenRoomsOrderingEnabled: userDomainVenue.is_sevenrooms_ordering_enabled,
    isSevenRoomsOrderingMiddleware: userDomainVenue.is_sevenrooms_ordering_middleware,
    isRoInsightEnabled: userDomainVenue.is_ro_insight_enabled,
    isFreedompayHpcEnabled: userDomainVenue.is_freedompay_hpc_enabled,
    superheroRoAccess: userDomainVenue.superhero_ro_access,
    orderingMiddlewareSource: userDomainVenue.menu_integration_source,
    serviceStatuses: userDomainVenue.service_statuses,
    clientLookupHotelList: userDomainVenue.client_lookup_hotel_list,
    sendEmailConfirmationsByDefault: userDomainVenue.send_email_confirmations_default,
    smsBookingNotificationEnabled: userDomainVenue.sms_booking_notification_enabled,
    remindersSmsEnabled: userDomainVenue.reminders_sms_enabled,
    shiftReminderTimes: userDomainVenue.reminder_send_times_by_shift,
    defaultReminderMethod: userDomainVenue.send_reminder_default_method,
    defaultDeliveryMethod: userDomainVenue.send_booking_delivery_method,
    shiftFeedbackTimes: userDomainVenue.feedback_send_times_by_shift,
    confirmationIncludeEndTime: userDomainVenue.confirmation_include_end_time,
    showPerksField: userDomainVenue.show_perks_field,
    costOptions: userDomainVenue.cost_options,
    landingPageEnabled: userDomainVenue.landing_page_enabled,
    lockContactInfoOnPresenceOfThisField: userDomainVenue.lock_contact_info_on_presence_of_this_field,
    lockableClientFields: userDomainVenue.lockable_client_fields,
    alwaysLockedClientFields: userDomainVenue.always_locked_client_fields,
    accountId: userDomainVenue.account_id,
    connectedSetupIntents: userDomainVenue.connected_setup_intents,
    customFieldsConfig: userDomainVenue.custom_fields_config,
    paymentType: userDomainVenue.payment_system,
    payoutProfileId: userDomainVenue.payout_profile_id,
    bookSettings: {
      requireEitherPhoneOrEmail: userDomainVenue.booking_res_require_either_phone_email,
      requirePhone: userDomainVenue.booking_res_require_phone,
      requireEmail: userDomainVenue.booking_res_require_email,
      requireLastName: userDomainVenue.booking_res_require_lname,
      requireSalutation: userDomainVenue.booking_res_require_salutation,
      defaultSeatingAreaId: userDomainVenue.default_seating_area,
      showSeatingAreaCovers: userDomainVenue.show_availability_seatingarea_covers,
      paymentsEnabled: userDomainVenue.payments_enabled,
      paylinkOnly: userDomainVenue.paylink_only,
      isMotoEnabled: userDomainVenue.is_moto_enabled,
      taxGroups: userDomainVenue.tax_groups,
      canSaveCard: userDomainVenue.payment_can_save_card,
      paymentSystem: userDomainVenue.payment_system,
      paymentPublicToken: userDomainVenue.payment_public_token,
    },
  }
  return { ...venue, permissions: ManagerPermissions.get(venue) }
}
